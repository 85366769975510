import cn from 'classnames';

import type {BlockProps} from '@/components/brochureV2/primitive/Block/types';
import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';
interface HeroCardProps {
  blocks: BlockProps[];
  className?: string;
  icon?: string;
  hasLink?: boolean;
}

const HeroCard = ({blocks, className, icon, hasLink}: HeroCardProps) => {
  // limit number of sell points to 3
  const cardBlocks = [...blocks];
  cardBlocks.length = Math.min(cardBlocks.length, 3);

  return (
    <div
      className={twMerge(
        `mx-auto w-11/12 max-w-7xl bg-white p-5 sm:p-10 rounded-2xl shadow-sm bottom-0 -translate-y-20 grid md:grid-cols-3 gap-6 container`,
        className,
      )}
    >
      {blocks.map((block: any) => (
        <div
          key={block.heading}
          className={cn('border-r-0 md:border-r md:border-shade-90/[.15]', {
            'last-of-type:border-none': blocks.length === 3,
          })}
        >
          {hasLink ? (
            <a key={block.heading} href={block.anchor}>
              <li className="flex items-baseline underline hover:no-underline">
                <Typography size="t7" as="h3" className="mb-2 md:mb-4">
                  {block.heading}
                </Typography>
                <span>
                  <img
                    className="h-[10px] w-[13px] ml-4 mr-6"
                    src={icon}
                    alt=""
                  />
                </span>
              </li>
            </a>
          ) : (
            <Typography size="t7" as="h3" className="mb-2 md:mb-4">
              {block.heading}
            </Typography>
          )}
          <Typography as="p" size="body-sm" className="max-w-prose md:pr-1">
            {block.contentHtml}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default HeroCard;
